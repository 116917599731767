<!-- Finder particular de Jefes de Zona -->

<template>
  <div class="jefeszona_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera" v-if="Entorno.header.header">       
          <base_Header 
            :Entorno="Entorno.header"
            @onEvent="event_Header">          
          </base_Header>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:800px"> 
          <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
            <div style="display:flex; align-items:center">     
              <v-row no-gutters>
                <v-col md="6">
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.name.value"
                    :label="schema.ctrls.name.label"
                    @keypress.13="buscarExec('buscarNombre')"
                  >
                  </v-text-field>
                </v-col>

                <v-col md="1">
                  <v-btn v-bind="$cfg.btn.busca" @click="buscarExec('buscarNombre')">
                    <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                  </v-btn>                  
                </v-col>
              </v-row>
            </div>
          </div>
        

          <!-- Grid -->
          <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid" dense>
            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">         
              <div style="padding:10px 0 0 10px;">
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                </btracombi>
              </div>
            </template>
            

            <!-- Slot campo acciones del Grid -->
            <template v-slot:acciones="{ Sitem }">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </template>
          </base_Fgrid>
        </div>
      </v-sheet>


      <!-- Mto como ventana modal -->      
      <v-dialog
        content-class="modal" persistent
        v-model="modal">
          <div class="centrado">             
            <component                 
                :is="component_M"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>   
    </div>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");  
  const jefeszona_M = () => plugs.groute("jefeszona_M.vue", "comp");  

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, jefeszona_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,
        api:'jefeszona_F',
        stName:'stFjefeszona',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,

        headers: {},
      };
    },

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Coordinador de Servicios";  

        this.headers = {
          header: [
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Nombre", value: "name", witdh: "30%" },
            { text: "Código", value: "codigo", witdh: "60%" }         
          ],

        };

        this.Entorno.grid.headers = this.set_headers();

        // items
        /* this.jefeszona= [
          {id:"0", zona:'(Jefe de Zona / Territorial)'},
          {id:"1", zona:'JAVIER GIL HEREDIA - CATALUÑA'}
        ] */

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'jefeszona_M';
       
      },  

    }
  };
</script>
